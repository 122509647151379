import React, { useState } from "react";
import { TideItemForFaqQuestionComponent } from "../../types";

interface AccordionProps {
  questions?: TideItemForFaqQuestionComponent[];
}

const Accordion: React.FC<AccordionProps> = ({ questions }) => {
  const [activeQuestion, setActiveQuestion] = useState("");

  const handleQuestionTrigger = (questionId: string) => {
    setActiveQuestion(questionId != activeQuestion ? questionId : "");
  };

  return (
    <div className="accordion-wrapper">
      {questions &&
        questions.map(
          (question, i) =>
            question.content?.general?.answer && (
              <div key={i} className={`accordion__section ${activeQuestion == question.id ? "accordion__section--active" : ""}`}>
                <button className="accordion" onClick={() => handleQuestionTrigger(question.id)}>
                  <p className="accordion__title">{question.content?.general?.question}</p>
                </button>
                <div className="accordion__content">
                  <div className="accordion__text" dangerouslySetInnerHTML={{ __html: question.content?.general?.answer }}></div>
                </div>
              </div>
            )
        )}
    </div>
  );
};

export default Accordion;
