import React from "react";

interface StaticIntroProps {
  title?: string;
  text?: string;
}

const StaticIntro: React.FC<StaticIntroProps> = ({ title, text }) => {
  return (
    <div className="static-intro">
      <div className="static-intro__container">
        <div className="static-intro__text">
          <div className="heading-seperator">
            <h1 className="static-intro__heading">{title}</h1>
          </div>
          {text && <div dangerouslySetInnerHTML={{ __html: text }}></div>}
        </div>
      </div>
    </div>
  );
};

export default StaticIntro;
