import React from "react";
import { TideItemForFaqDetailPage } from "../../types";
import { parse } from "../../utils/component";
import Link from "../link";

interface FAQCardsProps {
  faqs?: TideItemForFaqDetailPage[];
  id?: string;
}

const FAQCards: React.FC<FAQCardsProps> = ({ faqs, id }) => {
  return (
    <div className="faq-cards">
      {faqs &&
        faqs.map((faq, i) => (
          <div key={i} className={`faqcard ${faq.id == id ? "faqcard--active" : ""}`}>
            <Link path={`/faq/${faq.content?.general?.path}`} className="faqcard__anchor" title={parse(faq.content?.general?.title)}>
              <div className="faqcard__header">
                <i className={parse(faq.content?.general?.icon)} />
              </div>
              <div className="faqcard__body">
                <h2 className="faqcard__heading">{faq.content?.general?.title}</h2>
              </div>
            </Link>
          </div>
        ))}
    </div>
  );
};

export default FAQCards;
