import { graphql } from "gatsby";
import { get, isNil } from "lodash";
import React from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import Faq from "../../components/faq";
import Hero from "../../components/hero";
import { FaqPageTemplateData } from "../../templates/faq-page-template";
import { TideItemForHeroComponent, TideItemForOverviewComponent } from "../../types";
import { parse } from "../component";

interface MapFaqPageComponentsProps {
  data: FaqPageTemplateData;
  breadcrumbs: Breadcrumb[];
}

const MapFaqPageComponents: React.FC<MapFaqPageComponentsProps> = ({ data, breadcrumbs }) => {
  const componentRenderers = {
    TideItemForHeroComponent: (componentItem: TideItemForHeroComponent) => {
      return (
        <Hero
          key={componentItem.id}
          image={parse(data.page.content?.general?.hero)}
          breadcrumbs={breadcrumbs}
          showQsm={parse(componentItem.content?.general?.showQsm)}
          qsmSlimVersion={true}
          extraClass="header--list-page"
        />
      );
    },
    TideItemForOverviewComponent: (componentItem: TideItemForOverviewComponent) => {
      return (
        <Faq
          key={componentItem.id}
          id={parse(data.page.id)}
          title={parse(data.page.content?.general?.title)}
          questions={data.questions.nodes}
          faqs={data.faqs.nodes}
        />
      );
    },
  };

  return (
    <>
      {data.wildcardFaqPage?.childItems?.map((tideItem) => {
        const typeName = get(tideItem, "__typename");
        const renderer = get(componentRenderers, typeName);

        if (!isNil(renderer)) {
          return renderer(tideItem);
        }

        return null;
      })}
    </>
  );
};

export default MapFaqPageComponents;

export const query = graphql`
  fragment OverviewFragment on TideItemForOverviewComponent {
    __typename
    id
    content {
      general {
        title
      }
    }
  }
`;
