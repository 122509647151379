import { graphql, PageProps } from "gatsby";
import { isEmpty } from "lodash";
import React, { useContext, useEffect } from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import Footer from "../../components/footer";
import Layout from "../../components/layout";
import Navbar from "../../components/navbar";
import NotificationBar from "../../components/notification-bar";
import Seo from "../../components/seo";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import GlobalContext from "../../contexts/global-provider";
import {
  TideItemForFaqDetailPage,
  TideItemForFaqDetailPageConnection,
  TideItemForFaqQuestionComponentConnection,
  TideItemForUspFolder,
  TideItemForWebsite,
  TideItemForWildcardFaqPage,
} from "../../types";
import { parse } from "../../utils/component";
import MapFaqPageComponents from "../../utils/mappers/map-faq-page-components";

export interface FaqPageTemplateData {
  website: TideItemForWebsite;
  page: TideItemForFaqDetailPage;
  questions: TideItemForFaqQuestionComponentConnection;
  usps: TideItemForUspFolder;
  wildcardFaqPage: TideItemForWildcardFaqPage;
  faqs: TideItemForFaqDetailPageConnection;
}

interface FaqPageTemplateContext {
  language: string;
  breadcrumbs: Breadcrumb[];
}

const FaqPageTemplate: React.FC<PageProps<FaqPageTemplateData, FaqPageTemplateContext>> = ({ data, pageContext: { language, breadcrumbs } }) => {
  const globalContext = useContext(GlobalContext);
  const { notificationBar } = useContext<ComponentContextType>(ComponentContext);

  useEffect(() => {
    if (globalContext) {
      globalContext.setLanguage(language);
    }
  }, [globalContext]);

  return (
    <Layout>
      <Seo
        title={parse(data.page.content?.seo?.seoTitle)}
        description={parse(data.page.content?.seo?.seoDescription)}
        keywords={parse(data.page.content?.seo?.seoKeywords)}
        website={parse(data.website)}
        breadcrumbs={parse(breadcrumbs)}
      />
      {!isEmpty(notificationBar?.content?.general?.notification) && (
        <NotificationBar
          text={notificationBar?.content?.general?.notification ?? ""}
          icon={parse(notificationBar?.content?.general?.iconFontAwesome)}
        />
      )}
      <Navbar website={parse(data.website)} />
      <MapFaqPageComponents data={data} breadcrumbs={breadcrumbs} />
      <Footer name={parse(data.website.name)} logo={parse(data.website.content?.general?.logo)} />
    </Layout>
  );
};

export default FaqPageTemplate;

export const query = graphql`
  query FaqPageQuery($pageId: String, $websiteId: String) {
    wildcardFaqPage: tideItemForWildcardFaqPage(parentItem: { id: { eq: $websiteId } }) {
      __typename
      id
      name
      childItems {
        ...TideItemForHeroComponentFragment
        ...OverviewFragment
      }
    }
    website: tideItemForWebsite(id: { eq: $websiteId }) {
      name
      content {
        general {
          phone
          helptext
          linkSgr
          linkThuiswinkelWaarborg
          linkTelephone
          logo {
            altText
            url
            title
          }
          linkLogo
        }
      }
    }
    page: tideItemForFaqDetailPage(id: { eq: $pageId }) {
      name
      id
      content {
        general {
          hero {
            altText
            title
            url
          }
          title
          icon
          path
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
      }
    }
    questions: allTideItemForFaqQuestionComponent(
      filter: { content: { general: { rubricsIds: { eq: $pageId } } }, parentItem: { parentItem: { parentItem: { id: { eq: $websiteId } } } } }
    ) {
      nodes {
        id
        name
        content {
          general {
            question
            answer
            rubricsIds
          }
        }
      }
    }
    faqs: allTideItemForFaqDetailPage(filter: { content: { general: { website: { elemMatch: { id: { eq: $websiteId } } } } } }) {
      nodes {
        id
        content {
          general {
            title
            icon
            path
          }
        }
      }
    }
    usps: tideItemForUspFolder(parentItem: { id: { eq: $websiteId } }) {
      name
      childItems {
        ... on TideItemForUspItem {
          __typename
          id
          name
          content {
            general {
              fai
              text
              title
            }
          }
        }
      }
    }
  }
`;
