import React, { useContext } from "react";
import StaticIntro from "../static-intro";
import Accordion from "../accordion";
import { TideItemForFaqDetailPage, TideItemForFaqQuestionComponent } from "../../types";
import FAQCards from "./faq-cards--category";
import Link from "../link";
import translate from "../../utils/translate";
import translations from "./translations.json";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";

interface FaqProps {
  id?: string;
  title?: string;
  questions?: TideItemForFaqQuestionComponent[];
  faqs?: TideItemForFaqDetailPage[];
}

const Faq: React.FC<FaqProps> = ({ id, title, questions, faqs }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  return (
    <div className="faq-category">
      <div className="faq-category__page">
        <div className="faq-category__header">
          <Link path="/faq" title={translate(translations, language, "ALL_QUESTIONS")} className="cta cta--return">
            {translate(translations, language, "ALL_QUESTIONS")}
          </Link>
        </div>

        <div className="faq-category__body">
          <StaticIntro title={title} />
          <Accordion questions={questions} />
        </div>

        <div className="faq-category__footer">
          <Link path="/faq" title={translate(translations, language, "ALL_QUESTIONS")} className="cta cta--return">
            {translate(translations, language, "ALL_QUESTIONS")}
          </Link>
        </div>
      </div>

      <div className="faq-category__sidebar">
        <h2 className="faq-category__sidebar-heading">{translate(translations, language, "QUESTIONS")}</h2>
        <FAQCards faqs={faqs} id={id} />
      </div>
    </div>
  );
};

export default Faq;
